<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
    <div class="flex-1 overflow-y-scroll relative">
      
      <PLoader v-if="isLoading" /> 

      <table v-else class="data-table table-fixed">
        <thead>
          <tr>
            <th class="w-16"></th>
            <th class="text-center w-40" v-html="$tk('Common.General.Date')"></th>
            <th class="text-left" v-html="$tk('Common.General.Address')"></th>
            <th class="text-left" v-html="$tk('Common.General.Url')"></th>
            <th class="text-left" v-html="$tk('Common.General.Country')"></th>
            <th class="text-left w-28" v-html="$tk('Common.General.Customer')"></th>
            <th class="text-left w-32" v-html="$tk('Common.General.Location')"></th>
            <th class="text-left w-40" v-html="$tk('Common.General.User')"></th>
            <th class="text-left" v-html="$tk('Common.General.Category')"></th>
            <th class="text-left" v-html="$tk('Common.General.Message')"></th>
            <th class="text-left" v-html="$tk('Common.General.Error')"></th>
          </tr>
        </thead>
        <tbody>          
          <tr 
            v-for="(entry, index) in filteredLog" 
            :key="index" 
            :class="{'odd': index % 2 !== 0 }"
            @click="onSelect(entry.id)"
          >
            <td class="text-center" :class="iconColor(entry.flags)">
              <FontAwesomeIcon 
                :icon="['far', iconName(entry.flags)]" 
              />              
            </td>
            <td class="text-center">
              {{ entry.createdLocal | dateAndTime }}
            </td>
            <td class="max-w-100 overflow-hidden truncate">
              {{ entry.remoteAddress }}
            </td>
            <td class="max-w-200 overflow-hidden truncate">
                {{ entry.url }}
            </td>
            <td>
              {{ entry.company }}
            </td>
            <td>
              {{ entry.customerId }}
            </td>
            <td>
              {{ entry.locationId }}
            </td>
            <td>
              {{ entry.userId }}
            </td>
            <td>
              {{ entry.category }}
            </td>
            <td class="overflow-hidden truncate">
              {{ entry.message }}
            </td>
            <td class="overflow-hidden truncate">
              {{ entry.error }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="filters">
      <div class="flex space-x-4">

        <PToggle
          :label="$tk('Log.Errors')"
          :stacked="true"
          v-model="showErrors"
        />

        <PToggle
          :label="$tk('Log.Warnings')"
          :stacked="true"
          v-model="showWarnings"
        />

        <PToggle
          :label="$tk('Log.Info')"
          :stacked="true"
          v-model="showInfo"
        />

        <PDatePicker 
          class="w-36" 
          :placeholder="$tk('Log.FromDate')" 
          v-model="createdLower" 
          :clearable="true" 
        />

        <PDatePicker 
          class="w-36" 
          :placeholder="$tk('Log.ToDate')" 
          v-model="createdUpper" 
          :clearable="true" 
        />

      </div>
    </portal>

    <LogDialog
      v-if="selectedId"
      :id="selectedId"
      @close="onCloseTicketDialog"
    />

  </div>
</template>

<script>

import http from "@/http"
import { filter } from "lodash"
import { lastDayOfMonth, startOfMonth, sub, format } from "date-fns"
import LogDialog from "../dialogs/LogDialog"

export default {

  components: {
    LogDialog
  },

  props: {
    id: {
      type: String,
      required: false
    }
  },

  data () {
    return {
      log: [],
      isLoading: false,
      showErrors: true,
      showWarnings: true,
      showInfo: true,
      createdUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      createdLower: format(startOfMonth(sub(new Date(), { days: 7 })), "yyyy-MM-dd"),
      selectedId: ""
    }
  },

  computed: {
    filteredLog () {
      return filter(this.log, l => {
        const isError = l.flags.indexOf("LevelError") >= 0
        const isWarning = l.flags.indexOf("LevelWarning") >= 0
        const isInfo = l.flags.indexOf("LevelInfo") >= 0
        return (this.showErrors && isError) ||
               (this.showWarnings && isWarning) ||
               (this.showInfo && isInfo)
      })
    },    
    dateRange() {
      return `${this.createdUpper}-${this.createdLower}`
    }
  },

  methods: {

    async getLog () {
      this.isLoading = true
      this.log = await http.get("Logs", 
      { 
        params: {
          // type: this.selectedType
           createdLower: this.createdLower,
           createdUpper: this.createdUpper
        }
      })
      this.isLoading = false      
    },

    async onSelect (id) {
      this.$router.replace({ 
        name: "global.Log", 
        params: { 
          id: `${id}` 
        } 
      })
      this.selectedId = `${id}`
    },

    async onCloseTicketDialog () {
      this.$router.replace({ name: "global.Log" })
      this.selectedId = ""      
    },


    // LevelInfo,LevelError,LevelWarning,Alert (fargelegg bakgrunn)

    iconColor (flags) {

      const isError = flags.indexOf("LevelError") >= 0
      const isWarning = flags.indexOf("LevelWarning") >= 0
      const isInfo = flags.indexOf("LevelInfo") >= 0      

      return isError ? "text-red-500" :
             isWarning ? "text-orange-500" : 
             isInfo ? "text-blue-500" : ""
    },

    iconName (flags) {

      const isError = flags.indexOf("LevelError") >= 0
      const isWarning = flags.indexOf("LevelWarning") >= 0
      const isInfo = flags.indexOf("LevelInfo") >= 0      

      return isError ? "exclamation-triangle" :
             isWarning ? "exclamation-triangle" : 
             isInfo ? "info-circle" : ""
    },

  },


  async created () {
    await this.getLog()    
  },

  watch: {

    id: {
      handler: function (val) {
        this.selectedId = val
      }, immediate: true
    },

    dateRange: function () {
      this.getLog()
    },

  }
}
</script>

<style scoped>

th {
  @apply p-4 leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme('colors.gray.50');
}

tr.odd {
  background-color: theme('colors.gray.100')
}

tr:hover {
  background-color: theme('colors.orange.200');
  cursor: pointer;
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

.max-w-200 {
  max-width: 200px;
}

</style>